import React from 'react';
import { Layout } from '../components/layout';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import PageSection from '../components/PageSection';
import PageHeading from '../components/PageHeading';
import PageContent from '../components/PageContent';

export default ({ data: { markdownRemark: page } }) => {
  const { title, description } = page.frontmatter;
  return (
    <Layout>
      <SEO title={title} description={description} />
      <PageSection>
        <PageHeading {...page.frontmatter} />
        <PageContent content={page.html} />
      </PageSection>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        heading
        image
      }
    }
  }
`;
