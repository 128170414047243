import React from 'react';
import PageHeadingImage from './PageHeadingImage';

interface PageHeadingProps {
  title: string;
  heading: string;
  image?: string;
}

const PageHeading = ({ title, heading, image }: PageHeadingProps) => (
  <React.Fragment>
    <div className="jumbotron">
      <h1 className="h1-responsive">{heading || title}</h1>
    </div>
    <PageHeadingImage image={image} />
  </React.Fragment>
);

export default PageHeading;
